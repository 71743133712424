.c-wrapper-widgets {
  @include breakpoint($md) {
    padding-left: var(--step-2);
  }  
}

#right .widget {
	width: 16em;
	margin-left: 2em;
}

#right .widget .img {
	margin-bottom: 0.8em;
}

#right .widget p.name {
	font-size: 1.4em;
	font-weight: bold;
	margin-bottom: 0.15em;
}

#right .widget p.funktion {
	font-style: italic;
	color: #666;
}

/* -------- Zwischentitel -------- */
#right .widget.zwischentitel h3 {
	font-size: 1.2em;
	margin: 0 0 0.5em 0;
}

/* -------- ap -------- */
#right .widget.ap h3 {
	font-size: 1.2em;
	font-weight: normal;
	color: #333;
	margin: 0 0 0.3em 0;
	padding: 0;
}

#right .widget.ap p.phone {
	font-size: 1.2em;
	line-height: 1.4em;
	margin-bottom: 0.25em;
}

/* -------- address -------- */
#right .widget.address .thumbMap {
	margin-top: 2em;
}

#right .widget.address .thumbMap img {
	border: 1px solid #999;
}
