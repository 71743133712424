.c-dev-banner {
	position: fixed;
	left: 0;
	bottom: 0;
	margin: 0;
	padding: 1rem;
	background-color: yellow;
	width: 100vw;
	text-align: center;
	font-size: 1rem;
}