// clearfix
.u-clearfix {
  &:after {
    display: table;
    clear: both;
    content: ' ';
  }
}

// Hide visually and from screen readers
//»[aria-hidden="true"]« nicht verwenden; funktioniert nicht mit slick-slider
.u-hidden {
  display: none !important;
}

[hidden] {
  display: none;
}

// Hide only visually, but have it available for screen readers:
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
.u-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// visuallyhiddenFocusable:
// Extends the .visuallyhidden class to allow the element
// to be focusable when navigated to via the keyboard:
// https://www.drupal.org/node/897638
.u-visuallyhidden--focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// invisible:
// Hide visually and from screen readers, but maintain layout
.u-invisible {
  visibility: hidden;
}

// Image-replacement:
// Hides text for image replacement
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757
.u-ir,
.u-hide-text {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: #{0 / 0} a;
  text-shadow: none;
}

.u-nowrap {
  white-space: nowrap;
}

.u-right {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.u-center {
  justify-content: center;
  display: flex;
}

.color-primary {
  color: color('brand-primary');
}

.color-grey-700 {
  color: color('grey-700');
}

.mb-3 {
  margin-bottom: var(--step-3);
}

.pb-3 {
  padding-bottom: var(--step-3);
}

.bg-transparent {
  background-color: transparent;
}

.s--2 {
  font-size: var(--step--2);
}

.s--1 {
  font-size: var(--step--1);
}

.s-0 {
  font-size: var(--step-0);
}

.s-1 {
  font-size: var(--step-1);
}

.s-2 {
  font-size: var(--step-2);
}

.s-3 {
  font-size: var(--step-3);
}

.s-4 {
  font-size: var(--step-4);
}

.display-block {
  display: block;
}