// Typography mixins
// -----------------

// Font weight
// -----------
@mixin fw($weight, $target: primary) {
  font-weight: map-fetch($font-weights, $target $weight);
}

// Font Family
// -----------
@mixin ff($family: primary, $map: $font-families) {
  font-family: map-get($map, $family);
  font-style: normal;
  font-weight: map-get($font-weight-default, $family);
}

// Type-scale
// ----------
// Gets font-size and baseline from typography map located
// in variables/typography and creates the necessary
// font-size and line-height property in CSS with the
// compass vertical rhythm.
//
// This uses the @include adjust-font-size-to mixin and
// requires Compass Vertical Rhythm to be used.
//
// https://medium.com/p/8e51498c3713
// ==========
@mixin type-scale($scale, $map: $type-scale) {
  $base-lines: map-fetch($map, $scale base-lines);
  $font-size: map-fetch($map, $scale font-size);
  $ms: map-fetch($map, $scale modularscale);  
  
  @debug $ms;
  
  @include adjust-font-size-to($font-size, $base-lines);
  //@include padding-leader(.125);
  //@include padding-trailer(.125);
}

// Text overflow
// -------------
// Requires inline-block or block for proper styling
@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Text input
// ------------
// Calls addresses all text inputs in one selector
$text-input-types: text, password, date, datetime, datetime-local, month, week, email, number, search, tel, time, url, color !default;

@mixin text-input($types: $text-input-types) {
  $selectors: ();
  @each $type in $types {
    $selector: unquote('input[type='#{$type}']');
    $selectors: append($selectors, $selector, comma);
  }

  & #{$selectors} {
    @content;
  }
}

@mixin hyphen($bp) {
  @include set-hyphen;

  @if $bp == 'small-xl' {
    @include breakpoint($small-xl) {
      @include clear-hyphen;
    }
  }

  @if $bp == 'med-s' {
    @include breakpoint($med-s) {
      @include clear-hyphen;
    }
  }

  @if $bp == 'med-l' {
    @include breakpoint($med-l) {
      @include clear-hyphen;
    }
  }

  @if $bp == 'large' {
    @include breakpoint($large) {
      @include clear-hyphen;
    }
  }

  @if $bp == 'wide' {
    @include breakpoint($wide) {
      @include clear-hyphen;
    }
  }
}

@mixin set-hyphen {
  display: inline;
  &:before {
    content: '-';
    display: inline-block;
  }
}

@mixin clear-hyphen {
  display: none;
  &:before {
    content: '';
  }
}

@mixin sgl-paddings-custom($padding-top-step, $padding-bottom-step, $unit: $sgl-base-unit, $base-value: $sgl-root-font-size) {
    $line-height: round($base-value * $sgl-base-line-height);
    $padding-top: 0;
    $padding-bottom: 0;
    
    @if ($padding-top-step != 'null') {
      $padding-top: $line-height * $padding-top-step;
    }
    @if ($padding-bottom-step != 'null') {
      $padding-bottom: $line-height * $padding-bottom-step;
    }

    //@if $sgl-debug-mode == true {
    //    @include sgl-show-margins($padding-top, $padding-bottom, $base-value, $unit);
    //}

    @if $unit == "px" or $unit == "pxrem" {
        @if ($padding-top-step != 'null') {
          padding-top: $padding-top * 1px;
        }
        padding-bottom: $padding-bottom * 1px;
    }

    @if $unit == "rem" or $unit == "pxrem" {
        @if ($padding-top-step != 'null') {
          padding-top: sgl-decimal-ceil(sgl-rem-calc($padding-top, $base-value), 5);
        }
        padding-bottom: sgl-decimal-ceil(sgl-rem-calc($padding-bottom, $base-value), 5);
    }

    @if $unit == "em" {
        @if ($padding-top-step != 'null') {
          padding-top: sgl-decimal-ceil(sgl-em-calc($padding-top, $base-value), 5);
        }
        padding-bottom: sgl-decimal-ceil(sgl-em-calc($padding-bottom, $base-value), 5);
    }
}

@mixin set-heading1-default {  
  @include sgl-heading(2, 1.5, 0, 0); 
 
  .is-base--1low & {
    @include sgl-heading(1, 1.25, 0, 0);      
  }

  .is-base--1up & {
    @include sgl-heading(4, 2.75, 0, 0);
  }

  @include breakpoint($small-s) {
    .is-small-s--1up & {
      @include sgl-heading(3, 2, 0, 0);
    }
  }

  @include breakpoint($small-xl) {
    &,
    // reset
    .is-base--1low &, 
    .is-base--1up &, 
    .is-small-s--1up & {
      @include sgl-heading(4, 2.75, 0, 0);
    }

    .is-small-xl--1low & {
      @include sgl-heading(3, 2, 0, 0);
    }

    .is-small-xl--1up & {
      @include sgl-heading(5, 3.5, 0, 0);
    }
  }

  @include breakpoint($med-l) {
    &, 
    // reset
    .is-base--1low &, 
    .is-base--1up &, 
    .is-small-s--1up &, 
    .is-small-xl--1low &, 
    .is-small-xl--1up & {// reset
      @include ff('light');
      @include sgl-heading(3, 2, 0, 0);
    }

    .is-med-l--1low & {
      @include sgl-heading(2, 1.5, 0, 0);
    }

    .is-med-l--1up & {
      @include sgl-heading(4, 2.75, 0, 0);
    }
  }

  @include breakpoint($large) {    
    &, 
    // reset
    .is-base--1low &, 
    .is-base--1up &, 
    .is-small-s--1up &, 
    .is-small-xl--1low &, 
    .is-small-xl--1up &, 
    .is-med-l--1low &, 
    .is-med-l--1up & {// reset
      @include sgl-heading(4, 2.75, 0, 0);
    }
  }

  @include breakpoint($mega) {
    //@include sgl-heading(5, 3.5, 0, 0);
    
    &, 
    // reset
    .is-base--1low &, 
    .is-base--1up &, 
    .is-small-s--1up &, 
    .is-small-xl--1low &, 
    .is-small-xl--1up &, 
    .is-med-l--1low &, 
    .is-med-l--1up &, 
    .is-large--1low &, 
    .is-large--1up & {// reset
      @include sgl-heading(5, 3.5, 0, 0);
    }
  }
}

@mixin set-heading1-services {
  @include sgl-heading(3, 2, 0, .25);

  @include breakpoint($small-xl) {
    @include sgl-heading(4, 2.75, 0, .5);
  }

  @include breakpoint($med-l) {
    @include sgl-heading(5, 3.5, 0, .5);
  }

  @include breakpoint($large) {
    @include sgl-heading(6, 4.5, 0, .5);
  }

  @media (orientation: landscape) {
    @include sgl-heading(2, 1.5, 0, .25);

    @include breakpoint($med-l) {
      @include sgl-heading(3, 2, 0, .25);
    }

    @include breakpoint($large) {
      @include sgl-heading(4, 2.75, 0, .5);
    }

    @include breakpoint($mega) {
      @include sgl-heading(5, 3.5, 0, .25);
    }
  }  
}

@mixin set-heading2-services {
    @media (orientation: landscape) {
      @include sgl-heading(0, .5, 0, .5);

      @include breakpoint($med-l) {
        @include sgl-heading(1, 1.25, 0, .25);
      }
      
      //@include breakpoint($large) {
      //  @include sgl-heading(1, 1.25, 0, .25);
      //}

      @include breakpoint($large) {
        @include sgl-heading(2, 1.25, 0, .25);
      }
      
      //@include breakpoint($wide-l) {
      //  @include sgl-heading(2, 1.25, 0, .75);
      //}
      
      @include breakpoint($mega) {
        @include sgl-heading(3, 2, 0, .5);
      }
    }

    @media (orientation: portrait) {
      @include sgl-heading(1, 1.25, 0, .25);
      
      @include breakpoint($small-xl) {
        @include sgl-heading(2, 1, 0, .75);
      }
      
      @include breakpoint($large) {
        @include sgl-heading(3, 2.5, 0, .75);
      }
      
      @include breakpoint($mega) {
        @include sgl-heading(4, 2.5, 0, .75);
      }
    }  
}
