.c-wrapper {
  background-color: color('grey-100');
  overflow: hidden;
  /*.p-stellenangebote &,
  .p-datenschutz &,
  .p-danke & {// index Stellenangebote
    background-position: 2.3em 1.6em;
  }*/
}

/* structure
--------------------------------------------------------------------------------
.c-wrapper--nav-main
    .c-wrapper--nav-main__body
      nav

.c-wrapper--content
    .c-wrapper--content__body
      main
    aside
--------------------------------------------------------------------------------
*/

.c-wrapper--content {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.c-wrapper--content__body {
  background-color: color('white');
  flex: 0 1 100%;

  padding-left: var(--step-4);
  padding-right: var(--step-2);
}

body:not(.p-frontpage){
  .c-wrapper--content__body {
    @include breakpoint($sm) {
      padding-left: var(--step-8);
    }
  }
  
  /* md-x
  ----------------------------------------------------------------------------- */
  @include breakpoint($md_x) {
    .c-wrapper--nav-main {  
      bottom: 0;
      display: flex;
      flex-wrap: nowrap;
      position: absolute;
      width: 100%;    
    }

    .c-wrapper--content {
      flex-wrap: nowrap;
    }

    .c-wrapper--nav-main__body, 
    .c-wrapper--content__body {
      flex-basis: 76%;
      position: static;
    }  
  }
  
}
