p.intro, 
.weTextarea.intro {
  font-size: var(--step-1);
  line-height: 1.5;
  font-style: italic;
  margin-bottom: var(--step-1);
}

//.weTextarea.intro {
//  font-size: 1em;
//}

/*
.center ul, .weTextarea ul {
	list-style-image: url('../img/layout/li_center.gif');
	margin-left: 1.7em;
}
.center li, .weTextarea li {
  //font-size: 1.3em;
  //line-height: 1.5em;
  margin-bottom: 0.6rem;
}

.center li li, 
.center li p, 
.weTextarea li li, 
.weTextarea li p {
  //font-size: 1em;
  margin-bottom: 0.3rem;
}

.center li p,  
.weTextarea li p {
  margin-bottom: 0.6rem;
}

.center span.AbstandZuIntro, .weTextarea span.AbstandZuIntro {
  display: block;
  margin-top: 2.5rem;
}
*/

/*div.introStellenangebot {
	font-size: 1.2em;
}*/

//.weTextarea.introStellenangebot {
//  font-size: 0.75rem;
//}
/*
div.introStellenangebot h2, 
.weTextarea.introStellenangebot h2 {
  color: color('brand-primary');
  margin: 0 0 0.6rem 0;
  line-height: 1.5;
}
*/
//.weTextarea.headlineStellenangebot {
  //font-size: 1.2em;
//}
