main {
  margin: 0 auto;
  max-width: var(--max-width-ch);
  padding-top: var(--step-4);
  padding-bottom: var(--step-4);
  //var(--step-8) var(--step-4) calc(var(--step-8) * 1.333);
  
  .p-frontpage & {
    max-width: 80%;
  }
}
