// 
//    http://hugogiraudel.com/2014/03/03/another-sass-button-library/
//

// Configuration
$btn-name: 'button' !default;
$btn-hover: darken 10% !default;
$btn-border: darken 10% !default; // Set to false for no border
$btn-size-ratio: 1.3333 !default;
$btn-schemes: (
  default: color('brand-primary'),
  submit: color('success'),
  success: color('success'),
  danger:  #c0392b,
  warning: #f1c40f,
  info:    #2980b9
) !default;

// Color helper 
// 1. Background-color
// 2. On hover
// 3. Border-color
@mixin button-color($color) {
  $everything-okay: true;

  // Making sure $color is a color
  @if type-of($color) != color {
    @warn '#{$color} is not a color for »button-color«';
    $everything-okay: false;
  }

  // Making sure $btn-hover and $btn-border are 2 items long
  @if length($btn-hover) != 2
    or length($btn-border) != 2 {
    @warn 'Both `$btn-hover` and `$btn-border` should be two items long for `button-color`.';
    $everything-okay: false;
  }

  // Making sure first items from $btn-hover and $btn-border are valid functions
  @if not function-exists(nth($btn-hover, 1)) 
    or not function-exists(nth($btn-border, 1)) {
    @warn 'Either `#{nth($btn-hover, 1)}` or `#{nth($btn-border, 1)}` is not a valid function for `button-color`.';
    $everything-okay: false;
  }

  // Making sure second items from $btn-hover and $btn-border are percentages
  @if type-of(nth($btn-hover, 2)) != number 
    or type-of(nth($btn-border, 2)) != number {
    @warn 'Either `#{nth($btn-hover, 2)}` or `#{nth($btn-border, 2)}` is not a valid percentage for `button-color`.';
    $everything-okay: false;
  }

  // If there is no mistake
  @if $everything-okay == true {
    color: color('white');
    background-color: $color; // 1

    // todo
    // DEPRECATION WARNING: Passing a string to call() is deprecated and will be illegal
    // in Sass 4.0. Use call(get-function("darken")) instead.
    &:hover,
    &:active { // 2
      //background: call(nth($btn-hover, 1), $color, nth($btn-hover, 2));
    }  

    @if $btn-border != false { // 3
      //border-color: call(nth($btn-border, 1), $color, nth($btn-border, 2));
    }
  }
}

@mixin btn-hamburger(
  $mod, 
  $colorActive: color('white'),
  $colorInactive: color('white')
) {
  .c-toggle--#{$mod}__label {
    position: relative;
    display: inline-block;
    width: auto;//40px;
    //height: 28px;
    text-indent: 32px;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    //background: color('white');
    color: $colorInactive;
    height: 40px;
    margin-bottom: -4px;
    padding-top: 10px;
    padding-right: 0;
  }

  // hamburger icon
  // --------------
  .c-toggle--#{$mod}__icon, 
  .c-toggle--#{$mod}__icon:before, 
  .c-toggle--#{$mod}__icon:after {
    position: absolute;
    top: 50%;
    left: 0;
    height: 2px;
    width: 24px;
    background-color: $colorInactive;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
  }

  .c-toggle--#{$mod}__icon:before {
    content: '';
    top: -7px;
    left: 0;
  }

  .c-toggle--#{$mod}__icon:after {
    content: '';
    top: 7px;
    left: 0;
  }
  
  .c-toggle--#{$mod}__state {
    @include breakpoint($med) {
      /* hide the button in desktop view */
      .c-toggle--#{$mod}__label {
        position: absolute;
        top: -99999px;
      }
      @if $mod == mainnav {
        /* always show the menu in desktop view */
        &:not(:checked) ~ #nav-main {
          display: block;
        }
      }
    }
  }

  // x icon
  // ------
  #c-toggle--#{$mod}__state:checked ~ .c-toggle--#{$mod}__label {
    color: $colorActive;
  }
  
  #c-toggle--#{$mod}__state:checked ~ .c-toggle--#{$mod}__label .c-toggle--#{$mod}__icon {
    height: 0;
    background: transparent;
  }

  #c-toggle--#{$mod}__state:checked ~ .c-toggle--#{$mod}__label .c-toggle--#{$mod}__icon:before {
    top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: $colorActive;
  }

  #c-toggle--#{$mod}__state:checked ~ .c-toggle--#{$mod}__label .c-toggle--#{$mod}__icon:after {
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: $colorActive;
  }

  /* hide menu state checkbox (keep it visible to screen readers) */
  #c-toggle--#{$mod}__state {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
    clip: rect(1px,1px,1px,1px);
  }
}


// ----------------
// hamburger/x icon
// ----------------
@mixin hamburger-x-button(
  $color: color(brand-primary)
) {
  .main-menu-btn {
    color: $color;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding-right: 1.75em;
    //padding-top: .25em;
    position: absolute;
    right: 1.5em;
    top: 1.5em;
    white-space: nowrap;
    width: auto;//40px;
    //height: 28px;
    //text-indent: 32px;
    z-index: 2;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    
    @include breakpoint($med) {
      font-size: .75em;
    }
  }

  // hamburger icon
  .main-menu-btn-icon, 
  .main-menu-btn-icon:before, 
  .main-menu-btn-icon:after {
    position: absolute;
    top: 46%;
    right: 0;
    height: 2px;
    width: 1.25em;
    background: $color;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
    
    .is-negative & {
      background: #fff;
    }
    
    .is-scrolled & {
      background: $color;
    }
  }

  .main-menu-btn-icon:before {
    content: '';
    top: -.375em;
    left: 0;

    @include breakpoint($med) {
      top: -.4em;
    }
  }

  .main-menu-btn-icon:after {
    content: '';
    top: .375em;
    left: 0;

    @include breakpoint($med) {
      top: .4em;
    }
  }
  
  
  #main-menu-state ~ .main-menu-btn {
    .is-negative & {
      color: color('white');
    }

    .is-scrolled & {
      color: $color;
    }
  }
  
  #main-menu-state:checked ~ .main-menu-btn {
    color: color('white');
  }

  // x icon
  #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
    height: 0;
    background: transparent;
  }

  #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
    top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: color('white');
  }

  #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: color('white');
  }

  /* hide menu state checkbox (keep it visible to screen readers) */
  #main-menu-state {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
    clip: rect(1px,1px,1px,1px);
  }

  /* hide the menu in mobile view */
  #main-menu-state:not(:checked) ~ .l-nav-main__container {
    left: 100%;
  }

  #main-menu-state:checked ~ .l-nav-main__container {
    left: 0;
  }

  @include breakpoint($nav) {
    /* hide the button in desktop view */
    .main-menu-btn {
      position: absolute;
      top: -99999px;
    }
    /* always show the menu in desktop view */
    #main-menu-state:checked ~ .l-nav-main__container, 
    #main-menu-state:not(:checked) ~ .l-nav-main__container {
      display: block;
      left: auto;
    }
  }
}

@mixin x-button {
  // x icon
  #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
    height: 0;
    background: transparent;
  }

  #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
    top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Default class
// 1. Border or not border?
// 2. Large modifier
// 3. Small modifier
// 4. Bold modifier
// 5. Block modifier
// 6. Uppercase modifier
// 7. Color themes modifier
.#{$btn-name} {
  // Default styles  
  font-family: map-get($font-families, 'primary');
  //@include border-radius(8px);
  border-radius: var(--step-2);

  //border-radius: .15em;
  //border: if($btn-border != false, 1px solid, none); // 1
  //box-shadow: inset 0 1px rgba(255, 255, 255, .2);
  
  border: 0 none;
  color: color('white');
  cursor: pointer;
  font-size: 1.25em;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  padding: .5em 2em;
  margin-top: 0.25em;
  text-decoration: none;
  transition: background .15s;

  // Modifiers
  &--large { // 2
    font-size: 1.25em * $btn-size-ratio;
  }

  &--small { // 3
    font-size: calc(1.25em / $btn-size-ratio);
  }

  &--bold { // 4
    font-weight: bold;
  }

  &--block { // 5
    display: block;
    width: 100%;
  }  

  &--upper { // 6
    text-transform: uppercase;
  }

  @each $key, $value in $btn-schemes { // 7
    &--#{$key} {
      @include button-color($value);
    }
  }
}
