.p-frontpage aside {
  display: none;

  p.intro {
    margin-bottom: var(--step-4);
  }
}

/*
.banner-hero--frontpage {
  margin-bottom: 36px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 10px #777;
}
*/



.teaserBox {
  //flex: 1;
  //float: left;
  //margin-right: 3%;
  //padding-right: 3%;
}

//.teaserBox + .teaserBox {
//  border-left: 1px solid color('grey-500');
//}

/*
.teaserBox.home:nth-child(4n+2),
.teaserBox.leistungen:nth-child(2n) {
  margin-right: 0;
  padding-right: 0;
  border-right: 0 none;
}
.teaserBox.home {
	width: 20%;
}
*/
/*
.teaserBox.barrierefreiesWohnen {
	float: none;
	margin-left: 51em;
	margin-right: 0;
	padding-right: 0;
	border-right: 0 none;
}
*/

.teaserBox.home h2 {
  font-size: var(--step-1);
  line-height: 1.2em;
  color: color('brand-primary');
  margin-top: 0;
}

.teaserBox h2 a {
  text-decoration: none;
}

/*.teaserBox p {
  height: 28em;
}*/

.teaserBox span.link {
  display: block;
  font-size: 1.3em;
  line-height: 1.5em;
  margin-bottom: 0.6em;
  text-align: right;
}
