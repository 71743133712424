.c-nav-main {
  background-color: color('brand-secondary');
}

.c-nav-main-toggle {
  background-color: color('brand-secondary');
  display: flex;
  align-items: center;
  padding-left: var(--step-0);
  padding-right: var(--step-0);
  position: relative;
  z-index: 2;

  @include breakpoint($md_x) {
    display: none;
  }
}
