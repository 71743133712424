.c-teaserboxes {
  display: flex;
  flex-wrap: wrap;
  gap: var(--step-4);
  margin-top: var(--step-2);
}

.c-teaserboxes--fp > * {
  flex: 1 1 20rem;
  
  display: flex;
  flex-wrap: wrap;
  gap: var(--step-4);
  
  & > * {
    flex: 1 1 10rem;    
  }
}

.c-teaserboxes--leistungen > * {
  flex: 1 1 15rem;
  
  img {
    width: 100%;
    height: auto;
  }
}

.c-teaserbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //flex: 1;
  margin-bottom: var(--step-2);
  
  h2 {
    font-size: var(--step-1);
    line-height: 1.2em;
    color: color('brand-primary');
    margin-top: 0;
    
    & + p {
      margin-top: 0;
    } 

    a {
      text-decoration: none;
    }
  }
  
  span.link {
    display: block;
    font-size: var(--step-0);
    //line-height: 1.5em;
    //margin-bottom: 0.6em;
    //text-align: right;
    align-self: flex-end;
  }
}
