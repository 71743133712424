.c-aside {
  padding-left: var(--step-4);
  
  @include breakpoint($sm) {
    padding-left: var(--step-8);
  }
  
  @include breakpoint($md) {
    padding-left: 0;
  }
  flex: 1 0 20%;
  padding-right: var(--step-2);
  padding-top: var(--step-2);
  position: relative;
  
  * + p,
  * + ul {
    margin-top: var(--step-0);
  }
  
  .c-wrapper-nav-main & {
    display: none;
    @include breakpoint($md) {
      display: block;
    }
  }
}
