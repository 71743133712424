.center div.ap {
	margin: 0 1em 1em 0;
}

.center div.ap .img {
	float: left;
}

/*
.center div.ap .data {
	margin-left: 15em;
}
*/

.center div.ap p {
	font-size: 1.5em;
}

.center div.ap p.name {
	font-size: 1.8em;
	font-weight: bold;
}

.center div.ap p.funktion {
	font-style: italic;
}


div#map_canvas {
width: 60em;
height: 60em;
}
