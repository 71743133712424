.nav-sub-level-1 {
  display: none;
  
  @include breakpoint($md) {
    display: block;
  }
  
  margin-bottom: 3rem;
  
  li + li {
    border-top: 1px solid color('grey-500');
  }
  
  li {
    a {
      display: block;
      font-size: var(--step-0);
      line-height: 1.3;
      padding: 1rem 2rem;
      background-color: color('white');
      text-decoration: none;
      color: color('grey-700');
      
      &.active, 
      &.inactive:hover {
          color: color('brand-primary');
          font-style: italic;
          font-weight: bold;
      }
    }
  }
}
