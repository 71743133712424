// Shape mixins
// -----
// Shapes availble within this mixin files are:
// ==========

// Square
// ------
@mixin square($size) {
  @include size($size, $size);
}

// Circle
// ------
@mixin circle($width) {
  border-radius: 100%;
  height: $width;
  width: $width;
}

// Triangle
// --------
// We use this to create isosceles triangles
// - $triangle-size       - Used to set border-size. No default, set a px or em size.
// - $triangle-color      - Used to set border-color which makes up triangle. No default
// - $triangle-direction  - Used to determine which direction triangle points. 
//                          Options: top, bottom, left, right
@mixin triangle($triangle-size, $triangle-color, $triangle-direction) {
  border: inset $triangle-size;
  content: '';
  display: block;
  height: 0;
  width: 0;
  @if $triangle-direction == top {
    border-color: $triangle-color transparent transparent;
    border-top-style: solid;
  }
  @if $triangle-direction == bottom {
    border-bottom-style: solid;
    border-color: transparent transparent $triangle-color;
  }
  @if $triangle-direction == left {
    border-color: transparent transparent transparent $triangle-color;
    border-left-style: solid;
  }
  @if $triangle-direction == right {
    border-color: transparent $triangle-color transparent transparent;
    border-right-style: solid;
  }
}

// arrows
// -----
// $dir: w(est) | e(ast) (default)
// $pos: before | after (default)
// $format: png | svg (default)
// $type: list | link (default) | list-label | label | slick | pagination | zoom
@mixin arrow($dir: e, $pos: after, $format: svg, $type: link) {
  //color: $brand-primary;
  text-decoration: none;

  @if $type == list {
    display: block;
    margin-left: 1.2rem;
  } @else if $type == list-label {
    display: block;
    margin-left: 1.5rem;
  } @else {
    display: inline-block;
  }

  @if $type == link {
    &:hover,
    &:focus {
      background-color: color(brand-primary);
      color: color('white');
    }
  }

  @if $type == list-label {
    &:hover,
    &:focus {
      background-color: color(brand-primary);
      color: color('white');
    }
  }

  @if $pos == before {
    &::before {
      @include arrowPseudo($dir, $format, $type);
    }

    @if $type == link {
      &:hover,
      &:focus {
        &::before {
          @if $dir == w {
            background-position: -19.9rem -4rem;// Wechsel auf weißen Pfeil
          } @else {
            background-position: 0 -4.15rem;// Wechsel auf weißen Pfeil
          }
        }
      }
    }
  } @else {
    &::after {
      @include arrowPseudo($dir, $format, $type);
    }

    @if $type == link {
      &:hover,
      &:focus {
        &::after {
          @if $dir == w {
            background-position: -1.4rem -4.15rem;// Wechsel auf weißen Pfeil
          } @else {
            //background-position: 0 -4.15em;// Wechsel auf weißen Pfeil
            background-position: -21.9rem -4rem;// Wechsel auf weißen Pfeil
          }
        }
      }
    }
  }
}

@mixin arrowPseudo($dir: e, $format: svg, $type: link) {
  background-repeat: no-repeat;
  background-size: auto 500%;
  content: '';
  display: inline-block;
  height: 1rem;
  vertical-align: sub;

  @if $type == list {
    margin-left: -1.2rem;
    margin-right: .3rem;
    //width: 0.6rem;
    width: .7rem;
    //background-size: auto 272%;
  }
  @else if $type == list-label {
    margin-left: -1.3rem;
    margin-right: 0;
    width: 1.1rem;
  }
  @else if $type == label {
    margin-right: .2rem;
    width: 1rem;
  }
  @else if $type == slick {
    font-size: 2rem;
    height: 2rem;
    width: 2rem;
  }
  @else if $type == pagination {
    //font-size: 1.6rem;
    height: 1.6rem;
    width: 1.6rem;
    margin-top: 0.1rem;
  }
  @else if $type == link {
    width: 1rem;
    @if $dir == w {
      margin-right: .25em;
    } @else {
      margin-left: .25em;
    }
  }
  @else if $type == hamburger {
    height: 1.6rem;
    margin-left: .3rem;
    width: 1.6rem;
  } @else {
    //width: 1.1em;
    width: 1rem;
    //background-size: auto 5em;//384%;
  }

  @if $format == png {
    //background-image: url('/img_static/icon-arrow-sprite.png');
    background-image: url('/assets/img/icons/icon-sprite-default.png');
  } @else {
    //background-image: url('/img_static/icon-arrow-sprite.svg');
    background-image: url('/assets/img/icons/icon-sprite-default.svg');
  }
  @if $dir == w {
    @if $type == list {
      background-position: -1.4rem -1.25rem;
    }
    @else if $type == slick {
      //background-position: -13.9rem 0;
      background-position: -27.9rem 0;
    }
    @else if $type == pagination {
      background-position: -20.6rem -3.2rem;
    } @else {
      background-position: -19.9rem -2rem;
    }
  } @else {
    @if $type == list {
      //background-position: 0 -1.25rem;
      background-position: -22rem -1rem;
    }
    @else if $type == list-label {
      background-position: -18.9rem -2rem;
    }
    @else if $type == label {
      background-position: -19rem 0;
    }
    @else if $type == slick {
      //background-position: -12.9rem 0;
      background-position: -25.9rem 0;
    }
    @else if $type == pagination {
      background-position: -22.3rem -3.2rem;
    }
    @else if $type == zoom {
      background-position: -30rem -4rem;
    }
    @else if $type == hamburger {
      background-position: -44.6rem -6.3rem;
    } @else {
      //background-position: 0 -1.4em;
      background-position: -21.9rem -2rem;
    }
  }
}
