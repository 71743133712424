.banner-hero {
  border-radius: var(--step--1);
  margin-bottom: var(--step-6);
  margin-left: -2rem;
  margin-right: -1rem;
  overflow: hidden;
  box-shadow: var(--step--2) var(--step--2) var(--step-2) 0 color('grey-500');
  
  @include breakpoint($sm) {
    margin-left: 0;
    margin-right: 0;
  }
}

.banner-hero__container {
  padding: var(--step-4) var(--step-8) var(--step-4) var(--step-4);
  position: relative;
}

.banner-hero__interrupter {
  background-color: #f90;
  color: color('white');
  display: inline-block;
  font-size: var(--step-1);
  font-weight: bold;
  right: calc(var(--step-8) * -2);//-30%;
  margin-bottom: -0.75em;
  padding: var(--step--2) calc(var(--step-8) * 2);
  position: absolute;
  text-align: center;
  top: var(--step-2);//7%;
  transform: rotate(30deg);
  /* box-shadow: 0.25em 0.25em 0.5em 0em #ccc; */
}

/**.banner-hero__subline {
  color: #06c;
  font-size: 2.368593037em;
  margin-bottom: 0.5em;
  margin-top: 2em;
  font-weight: bold;
}*/

.banner-hero__headline {
  color: color('brand-primary');
  margin-bottom: var(--step-0);
  margin-top: 2rem;
  //margin-top: calc(var(--step-4) * -1);//-2.5em;
  font-weight: bold;
  padding-right: var(--step-8);
  position: relative;
  
  @include breakpoint($sm) {
    margin-top: 0;
  }
}

.banner-hero__text h3 {
  margin: var(--step-2) 0 0 0;
  font-weight: bold;
}

.banner-hero__text li + li {
  margin-top: var(--step--2);
}
