.c-footer {
  //width: 73em;
  padding-left: var(--step-0);
  padding-top: var(--step-0);
  padding-right: var(--step-0);
  padding-bottom: var(--step-2);
  text-align: right;
  
  //&--frontpage {
  //  width: 90em;
  //}

  a, 
  span {
    font-size: var(--step--1);
  }

  span {
    padding: 0 var(--step--4);
  }
}
