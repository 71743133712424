$secondary: #89a; // "accept necessary" button color

.cookie-consent-modal {
  padding-top: 0;
  position: fixed;
  width: auto;
  height: auto;
  z-index: 1000;

  .modal-content-wrap {
    position: fixed;
    bottom: var(--step-4);
    margin: var(--step-0);

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }

    .modal-content {
      background-color: color('grey-100'); // the modals background color
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); // the modals shadow
      padding: 0;
      max-width: 768px;
      border-radius: 2px;
        
      & > * + * {
        margin-top: var(--step-0);
      }

      .modal-header {
        padding-top: var(--step--1);
        padding-right: var(--step-0);
        padding-left: var(--step-0);
      }

      .modal-body {
        font-size: var(--step--2);
        padding-right: var(--step-0);
        padding-bottom: var(--step--2);
        padding-left: var(--step-0);
        //line-height: 1.3;
        
        & > * + * {
          margin-top: var(--step-0);
        }

        a {
          color: color('brand-primary');

          &:hover {
            color: darken(color('brand-primary'), 10%);
          }
        }
        
        /*ul {
          list-style-image: url('../img/layout/li_center.gif');
          margin-left: var(--step-0);
        }*/
        
        li {
          position: relative;
          padding-left: 1.25em;

          &::before {
            @include triangle(.5em,color('brand-primary'), 'left');
            position: absolute;
            left: .25em;
            top: .25em;
          }
        }
      }

      .modal-footer {
        padding-right: var(--step-0);
        padding-bottom: var(--step--2);
        padding-left: var(--step--2);

        .buttons {
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;

          .btn {
            @extend .c-button;
            border: none;
            font-family: var(--font-primary);
            font-size: var(--step-0);
            margin-left: var(--step--2);
            margin-bottom: var(--step--2);

            // "accept all" button
            &.btn-primary, 
            &.btn-secondary {
              background-color: color('brand-secondary');
              color: color('black');

              &:hover {
                background-color: darken(color('brand-secondary'), 10%);
              }
            }
          }
        }
      }
    }
  }

  .btn {
    width: 100%;
    @include breakpoint($md_s) {
      width: auto;
    }
  }
  
  &.block-access {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5); // the fullscreen background layer
    padding-top: 20vh; // modals vertical position in desktop mode
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    @media screen and (max-width: 620px) {
      padding-top: 0;
    }

    .modal-content-wrap {
      position: relative;
      margin: 2.5% auto;

      .modal-content {
        border: none;
        margin: 0 auto;
      }

      bottom: auto;
      width: 95%;
    }
  }
}
