@use "sass:math";

@mixin line($color: color('black')) {
  display: inline-block;
  width: $button-size;
  height: math.div($button-size, 7);
  background: $color;
  border-radius: math.div($button-size, 14); 
  transition: $transition;
}

$button-size : 1rem; 
$transition: .25s; // increase this to see the transformations in slow-motion
 
.lines-button {
  display: inline-block;
  padding: 0 $button-size*0.25 math.div($button-size, 3) $button-size*0.25;
  transition: .25s;
  cursor: pointer;
  user-select: none;
  border-radius: math.div($button-size, 7);
 
  &:hover {
    opacity: 1;
  }
 
  &:active {
    transition: 0;
    background: rgba(0,0,0,.1);
  }
}

.lines {   
  //create middle line
  @include line;
  position: relative; 
 
  /*create the upper and lower lines as pseudo-elements of the middle line*/
  &:before, &:after {   
    @include line;
    position: absolute;
    left:0;
    content: '';
    transform-origin: math.div($button-size, 14) center;
  }
  &:before { top: math.div($button-size, 3.5); }
  &:after { top: math.div(-$button-size, 3.5); }
}

// Three-line to x (#2) 
.lines-button.x2 .lines {
    transition: background .25s .5s ease;
 
    &:before, &:after{
      //set transform origin back to center
      transform-origin: 50% 50%;
      transition: top .25s .5s ease, transform .3s ease;
    }
}

.lines-button.x2 .lines {
  [aria-expanded="true"] & {
    transition: background .25s 0s ease;
    background: transparent;

    &:before, &:after{
      transition: top .25s ease, transform .25s .5s ease;      
      top:0;
      width: $button-size;
    }
    &:before{
      transform: rotate3d(0,0,1,45deg); 
    }
    &:after{
      transform: rotate3d(0,0,1,-45deg); 
    }
  }
}

