/* index
----------------------------------------------------------------------------- */
.c-index-job__wrapper + .c-index-job__wrapper {
  margin-top: var(--step-3);
}

.c-index-job {
  li + li {
    margin-top: var(--step-1);
  }
}

.c-index-job__title {
  font-size: var(--step-0);
  
  & + .c-index-job {
    margin-top: var(--step--4);
  }
}

.c-index-job__headline {
  font-size: var(--step-1);
  font-weight: normal;

  & + .c-index-job__type {
    margin-top: var(--step--4);
  }
}

.c-index-job__type {
  a {
    color: color('grey-900');
    text-decoration: none;
  }
}
