* {
  box-sizing: border-box;
  
  &::after, 
  &::before {
    border-color: color('grey-500');
    border-style: solid;
    border-width: 0;
  }
  
  // Focus styles.
  &:focus {
    outline: 3px dotted color('brand-accent-1');
    outline-offset: -3px;

    /*&:not(:focus-visible) {
      outline: none;
      box-shadow: none;
    }*/
  }
}

html, body {
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  font-size: var(--step-0);
  line-height: 1.5;
  //font-size: 100%;//62.5%;/*IE*/
  background: color('grey-400') url('../img/layout/bg_body.gif') 0 0 repeat-x;
  font-family: var(--font-primary);
  color: color('grey-900');
  padding: 0;
  overflow-x: hidden;
  
  &.is-development,
  &.is-staging {
    //padding-bottom: var(--step-5);
  }
  
  @include breakpoint($sm) {
    padding-left: var(--step-4);
    padding-right: var(--step-4);
  }
}

/*html>body {
  font-size: 10px;
}*/

// Set Image and Objects to have a max-width of 100%
img,
embed,
iframe,
object,
video {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

address {
  display: block;
  font-style: normal;
}
