:root {
  --font-primary: "Trebuchet MS", "Trebuchet", Arial, Helvetica, Verdana, sans-serif;
  --max-width: 1600px;//960px;//
  --max-width-ch: 74ch;
  --max-content-width: 1440px;
  
  --fluid-min-width: 320;
  --fluid-max-width: 1600;
  --fluid-min-size: 12;
  --fluid-max-size: 18;
  --fluid-min-ratio: 1.2;
  --fluid-max-ratio: 1.333;

  --fluid-screen: 100vw;
  --fluid-bp: calc(
    (var(--fluid-screen) - ((var(--fluid-min-width) / 16) * 1rem)) /
      ((var(--fluid-max-width) / 16) - (var(--fluid-min-width) / 16))
  );
  
  --frame-n: 3.6;
  
  --bp-sm: 30em;//480px
  --bp-md-s: 40em;//640px
  --bp-md: 48em;//768px
  --bp-md-x: 64em;//1024px
  --bp-lg: 90em;//1440px
  --bp-mg: 100em;//1600px
  --bp-wd: 120em;//1920px
  --bp-wd-x: 138em;//2208px
  
  //--step--2: 0.5628rem;
  //--step--1: 0.7502rem;
  //--step-0: 1rem;
  //--step-1: 1.333rem;
  //--step-2: 1.7769rem;
  //--step-3: 2.3686rem;
  //--step-4: 3.1573rem;
}

@media screen and (min-width: 1600px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}

:root {
  --fluid-max-negative: (1 / var(--fluid-max-ratio) / var(--fluid-max-ratio));
  --fluid-min-negative: (1 / var(--fluid-min-ratio) / var(--fluid-min-ratio));
  
  --fluid-min-scale--4: var(--fluid-min-scale--3) * var(--fluid-min-ratio) * var(--fluid-min-negative);
  --fluid-max-scale--4: var(--fluid-max-scale--3) * var(--fluid-max-ratio) * var(--fluid-max-negative);
  --fluid-min-size--4: (var(--fluid-min-size) * var(--fluid-min-scale--4)) / 16;
  --fluid-max-size--4: (var(--fluid-max-size) * var(--fluid-max-scale--4)) / 16;
  --step--4: calc(((var(--fluid-min-size--4) * 1rem) + (var(--fluid-max-size--4) - var(--fluid-min-size--4)) * var(--fluid-bp))); 
  
  --fluid-min-scale--3: var(--fluid-min-scale--2) * var(--fluid-min-ratio) * var(--fluid-min-negative);
  --fluid-max-scale--3: var(--fluid-max-scale--2) * var(--fluid-max-ratio) * var(--fluid-max-negative);
  --fluid-min-size--3: (var(--fluid-min-size) * var(--fluid-min-scale--3)) / 16;
  --fluid-max-size--3: (var(--fluid-max-size) * var(--fluid-max-scale--3)) / 16;
  --step--3: calc(((var(--fluid-min-size--3) * 1rem) + (var(--fluid-max-size--3) - var(--fluid-min-size--3)) * var(--fluid-bp))); 
  
  --fluid-min-scale--2: var(--fluid-min-scale--1) * var(--fluid-min-ratio) * var(--fluid-min-negative);
  --fluid-max-scale--2: var(--fluid-max-scale--1) * var(--fluid-max-ratio) * var(--fluid-max-negative);
  --fluid-min-size--2: (var(--fluid-min-size) * var(--fluid-min-scale--2)) / 16;
  --fluid-max-size--2: (var(--fluid-max-size) * var(--fluid-max-scale--2)) / 16;
  --step--2: calc(((var(--fluid-min-size--2) * 1rem) + (var(--fluid-max-size--2) - var(--fluid-min-size--2)) * var(--fluid-bp))); 
  
  --fluid-min-scale--1: var(--fluid-min-ratio) * var(--fluid-min-negative);
  --fluid-max-scale--1: var(--fluid-max-ratio) * var(--fluid-max-negative);
  --fluid-min-size--1: (var(--fluid-min-size) * var(--fluid-min-scale--1)) / 16;
  --fluid-max-size--1: (var(--fluid-max-size) * var(--fluid-max-scale--1)) / 16;
  --step--1: calc(((var(--fluid-min-size--1) * 1rem) + (var(--fluid-max-size--1) - var(--fluid-min-size--1)) * var(--fluid-bp)));
  
  --fluid-min-scale-0: var(--fluid-min-ratio);
  --fluid-max-scale-0: var(--fluid-max-ratio);
  --fluid-min-size-0: (var(--fluid-min-size)) / 16;
  --fluid-max-size-0: (var(--fluid-max-size)) / 16;
  --step-0: calc(((var(--fluid-min-size-0) * 1rem) + (var(--fluid-max-size-0) - var(--fluid-min-size-0)) * var(--fluid-bp)));
  
  --fluid-min-scale-1: var(--fluid-min-scale-0) * var(--fluid-min-ratio);
  --fluid-max-scale-1: var(--fluid-max-scale-0) * var(--fluid-max-ratio);
  --fluid-min-size-1: (var(--fluid-min-size) * var(--fluid-min-scale-0)) / 16;
  --fluid-max-size-1: (var(--fluid-max-size) * var(--fluid-max-scale-0)) / 16;
  --step-1: calc(((var(--fluid-min-size-1) * 1rem) + (var(--fluid-max-size-1) - var(--fluid-min-size-1)) * var(--fluid-bp)));

  --fluid-min-scale-2: var(--fluid-min-scale-1) * var(--fluid-min-ratio);
  --fluid-max-scale-2: var(--fluid-max-scale-1) * var(--fluid-max-ratio);
  --fluid-min-size-2: (var(--fluid-min-size) * var(--fluid-min-scale-1)) / 16;
  --fluid-max-size-2: (var(--fluid-max-size) * var(--fluid-max-scale-1)) / 16;
  --step-2: calc(((var(--fluid-min-size-2) * 1rem) + (var(--fluid-max-size-2) - var(--fluid-min-size-2)) * var(--fluid-bp)));

  --fluid-min-scale-3: var(--fluid-min-scale-2) * var(--fluid-min-ratio);
  --fluid-max-scale-3: var(--fluid-max-scale-2) * var(--fluid-max-ratio);
  --fluid-min-size-3: (var(--fluid-min-size) * var(--fluid-min-scale-2)) / 16;
  --fluid-max-size-3: (var(--fluid-max-size) * var(--fluid-max-scale-2)) / 16;
  --step-3: calc(((var(--fluid-min-size-3) * 1rem) + (var(--fluid-max-size-3) - var(--fluid-min-size-3)) * var(--fluid-bp)));

  --fluid-min-scale-4: var(--fluid-min-scale-3) * var(--fluid-min-ratio);
  --fluid-max-scale-4: var(--fluid-max-scale-3) * var(--fluid-max-ratio);
  --fluid-min-size-4: (var(--fluid-min-size) * var(--fluid-min-scale-3)) / 16;
  --fluid-max-size-4: (var(--fluid-max-size) * var(--fluid-max-scale-3)) / 16;
  --step-4: calc(((var(--fluid-min-size-4) * 1rem) + (var(--fluid-max-size-4) - var(--fluid-min-size-4)) * var(--fluid-bp)));

  --fluid-min-scale-5: var(--fluid-min-scale-4) * var(--fluid-min-ratio);
  --fluid-max-scale-5: var(--fluid-max-scale-4) * var(--fluid-max-ratio);
  --fluid-min-size-5: (var(--fluid-min-size) * var(--fluid-min-scale-4)) / 16;
  --fluid-max-size-5: (var(--fluid-max-size) * var(--fluid-max-scale-4)) / 16;
  --step-5: calc(((var(--fluid-min-size-5) * 1rem) + (var(--fluid-max-size-5) - var(--fluid-min-size-5)) * var(--fluid-bp)));
  
  --fluid-min-scale-6: var(--fluid-min-scale-5) * var(--fluid-min-ratio);
  --fluid-max-scale-6: var(--fluid-max-scale-5) * var(--fluid-max-ratio);
  --fluid-min-size-6: (var(--fluid-min-size) * var(--fluid-min-scale-5)) / 16;
  --fluid-max-size-6: (var(--fluid-max-size) * var(--fluid-max-scale-5)) / 16;
  --step-6: calc(((var(--fluid-min-size-6) * 1rem) + (var(--fluid-max-size-6) - var(--fluid-min-size-6)) * var(--fluid-bp)));

  --fluid-min-scale-7: var(--fluid-min-scale-6) * var(--fluid-min-ratio);
  --fluid-max-scale-7: var(--fluid-max-scale-6) * var(--fluid-max-ratio);
  --fluid-min-size-7: (var(--fluid-min-size) * var(--fluid-min-scale-6)) / 16;
  --fluid-max-size-7: (var(--fluid-max-size) * var(--fluid-max-scale-6)) / 16;
  --step-7: calc(((var(--fluid-min-size-7) * 1rem) + (var(--fluid-max-size-7) - var(--fluid-min-size-7)) * var(--fluid-bp)));

  --fluid-min-scale-8: var(--fluid-min-scale-7) * var(--fluid-min-ratio);
  --fluid-max-scale-8: var(--fluid-max-scale-7) * var(--fluid-max-ratio);
  --fluid-min-size-8: (var(--fluid-min-size) * var(--fluid-min-scale-7)) / 16;
  --fluid-max-size-8: (var(--fluid-max-size) * var(--fluid-max-scale-7)) / 16;
  --step-8: calc(((var(--fluid-min-size-8) * 1rem) + (var(--fluid-max-size-8) - var(--fluid-min-size-8)) * var(--fluid-bp)));
}

$sm: 30em;//480px
$md_s: 40em;//640px
$md: 48em;//768px
$md_x: 64em;//1024px
$lg: 90em;//1440px
$mg: 100em;//1600px
$wd: 120em;//1920px
$wd_x: 138em;//2208px
