.stoerer {
	display: block;
	position: absolute;
	top: 1.8em;
	left: 29em;
	background-color: #f90;
	padding: .5em 1em;
	transform: rotate(-10deg);
	-ms-transform:rotate(-10deg);
	-o-transform:rotate(-10deg);
	-webkit-transform:rotate(-10deg);
	line-height: 1.5;
	color: #333;
	text-decoration: none;
	box-shadow: 4px 4px 8px #777;
	border-radius: 4px;
	overflow: hidden;
}

.stoerer__headline {
	font-size: 1.6em;
	display: block;
}

.stoerer__subline {
	font-size: 1.2em;
}
