.p-links .item {
	margin-bottom: 4em;
}

.p-links .item.pos1 {
	margin-top: 4em;
}

.p-links .item p.name {
	margin-top: 0.75em;
}
