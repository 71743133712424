.fancybox-content {
  padding: 0;
}

[data-fancybox],
.fancybox-zoom {
  cursor: zoom-in;
}

/*
.fancybox-zoom {
  &:after {
    transition: .5s;
    border-radius: 100%;
    background-color: rgba(color('brand-primary'),.5);
    bottom: 0.5em;
    color: rgba(color('white'), .75);
    content: "+";
    display: block;
    font-size: 4em;
    font-weight: bold;
    line-height: 1em;
    padding-bottom: .075em;
    position: absolute;
    right: 0.5em;
    text-align: center;
    width: 1em;
    
  }
  
  &:hover {
    &:after {
      background-color: rgba(color('brand-primary'),1);
      color: rgba(color('white'), 1);
    }
  }
}
*/
