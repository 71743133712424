// Minimum accessible target size.
@mixin make-target() {
  //min-width: 44px;
  min-height: 44px;
}

.c-nav-main {
  > button {
    @include make-target();
    border: 0;
    background-color: transparent;
  }
  
  > ul {// nur Level 0
    box-shadow: 0 0.25rem 0.5rem color('grey-600');
    opacity: 0;
    transition: all .25s ease;
    
    width: 25vw;
    position: absolute;
    left: -25vw;
    top: auto;

    @include breakpoint($md) {
      opacity: 1;
      display: grid;
      width: auto;
      position: static;
      left: auto;
    }

    &.show {
      background-color: color('brand-secondary');      
      opacity: 1;
      left: 0;
      width: 75vw;
    }
  }

  ul {// für alle
    margin: 0;
    padding: 0;
    list-style: none;

    &.show {
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: max-content;
    }
      
    li + li {
      border-top: 1px solid color('white');
    }    
  }

  a {
    align-items: center;
    color: color('black');
    display: flex;
    justify-content: flex-start;
    padding: .5rem;
    text-decoration: none;
    transition: background-color 150ms ease-in-out;
    
    &.active,
    &:hover,
    &:focus {
      background-color: color('brand-accent-1');
      color: color('white');
      
      .down {
        fill: color('white');
      }
    }
  }
  
  li.dropdown {
    position: relative;

    .down {
      margin-left: 0.25rem;
      transition: transform .125s linear;
    }

    & > a {      
      &[aria-expanded="true"] .down {
        transform: rotate(-180deg);
      }
      
      /*&::after {
        content: "▼";
        display: block;
        transition: transform 250ms linear;
      }

      &[aria-expanded="true"]::after {
        transform: rotate(-180deg);
      }*/
    }

    ul {
      //background-color: color('grey-300');
      background-color: color('white');
      display: none;
      
      li + li {
        border-top: 1px solid color('grey-400');
      }
      
      a {
        justify-content: flex-start;
        
        @include breakpoint($md_x) {
          white-space: nowrap;
        }
      }

      &.show {
        display: grid;
      }
    }
  }
}

@include breakpoint($md) {
  .c-nav-main {
    button {
      display: none;
    }

    > ul,
    > ul.show {
      box-shadow: none;
      display: grid;
      //grid-auto-columns: max-content;
      grid-auto-flow: column;
    }
    
    ul {
      
      li + li {
        border-top: 0 none;
      }      
    }
    
    a {
      justify-content: center;
    }

    .dropdown ul {
      box-shadow: 0 0.25rem 0.5rem color('grey-600');
    }
    
    .dropdown ul.show {
      position: absolute;
      min-width: 100%;
    }

    .dropdown ul ul {
      top: 0;
      left: 100%;
    }
  }
}
