@mixin u-frame {
  //--frame-n: 9; // in /config/_settings.scss
  --frame-d: 16;
  padding-bottom: calc(var(--frame-n) / var(--frame-d) * 100%);
  position: relative;
  overflow: hidden;
  .slick-slider & {
    overflow: visible;
  }

  & > * {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
    
    //.slick-slider & {
    //  height: 102%;
    //}
  }

  //.u-frame > img,
  //.u-frame > video {
  & > img,
  & > video, 
  & > iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    .slick-slider & {
      height: auto;
      max-width: 107%;
      width: 107%;
      
      @include breakpoint($md) {
        max-width: 103%;
        width: 103%;
      }
    }
  }  
}

@mixin u-frame-reset {
  padding-bottom: 0;

  & > * {
    position: relative;
  }
  
  & > img,
  & > video, 
  & > iframe {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.u-frame {
  @include u-frame;
}

/*
@include media-query('sm') {
  .sm\:u-frame {
    @include u-frame;
  }
}

@include media-query('md-s') {
  .md-s\:u-frame {
    @include u-frame;
  }
}

@include media-query('md') {
  .md\:u-frame {
    @include u-frame;
  }
}

@include media-query('md-x') {
  .md-x\:u-frame {
    @include u-frame;
  }
  .md-x\:u-frame-reset {
    @include u-frame-reset;
  }
}

@include media-query('lg') {
  .lg\:u-frame {
    @include u-frame;
  }
  
}

@include media-query('lg') {
  .lg\:u-frame-reset {
    @include u-frame-reset;
  }
}
*/