p, form, fieldset, img, h1, h2, h3, h4, h5, h6, ul, ol, li {
  margin: 0;
  padding: 0;
  border: 0 none;
}

h1, 
.weTextarea.headline {
  color: color('brand-primary');
  font-size: var(--step-3);//3.6em;
  font-weight: normal;
  font-style: italic;
  line-height: 1.25;
  margin-bottom: var(--step-0);
}

//.weTextarea.headline {
//  font-size: 2.2em;
//}

h2, 
.weTextarea h2 {
  color: color('brand-secondary');
  font-size: var(--step-2);//1.2rem
  font-weight: normal;
  line-height: 1.25;
  margin: var(--step-3) 0 var(--step-0) 0;
}

h3 {
  font-size: var(--step-1);
  font-weight: bold;
}

h4 {
  font-weight: normal;
}

p,
ul,
.weTextarea p {
  font-size: var(--step-0);
  line-height: 1.5;
}

ul, 
.weTextarea ul {
  list-style-type: none;
}

a {
  color: color('brand-primary');
}

/* editorial
----------------------------------------------------------------------------- */
.editorial {
  * + p,
  * + ul {
    margin-top: var(--step-0);
  }
  
  * + h3 {
    margin-top: var(--step-3);
  }
    
  ul
  //,.weTextarea ul
  {
    list-style-image: url('../img/layout/li_center.gif');
    margin-left: var(--step-0);
  }
  
  li + li {
    margin-top: var(--step--4);
  }
}

/* custom classes
----------------------------------------------------------------------------- */
a.arrowRight {
  background: url('../img/layout/linkRight.gif') right 0.3em no-repeat;
  padding-right: 1.1em;
}

/*
.clear {
  clear: both;
}

.clearLeft {
  clear: left;
}

.txtRight {
  text-align: right;
}
*/

a.pdf {
  background: url('../img/layout/acrobat_document_16x16.gif') no-repeat scroll 0 0.2em transparent;
  color: #003366;
  display: block;
  margin: 0;
  padding: 0 0 0 2em;
}

.c6 {
	color: #666;
}


.bildFliesstext {
	display: block;
	float:left;
	margin: 0 2em 1em 0;
	padding-bottom: 2em;
	background: url('../img/layout/zoomIn.gif') right bottom no-repeat;
	text-decoration: none;
}

span.hgStrothweg a.bildFliesstext {
	margin-right: 4em;
}


span.hgStrothweg a.bildFliesstext.kellergeschoss {
	margin-right: 0;
}

.bildFliesstext span.bildunterschrift {
	display: block;
	font-size: 1.2em;
	margin-bottom: 0.9em;
	color: #666;
}

img.imFliesstext {
	margin: 1em 1em 1em 0;
}

li img.imFliesstext {
	margin-bottom: 0;
}

.unsichtbar {
	display: none;
}

span.zoomSlider {
	display: block;
	position: relative;
	top: -20em;
	right: -96em;
	width: 2.6em;
	height: 3.6em;
	background: url('../img/layout/lupeSlider.gif') top right no-repeat;
	cursor: pointer;
}

/*li.panel span.zoomSlider {
	position: absolute;
	top: 2em;
	left: 189.4em;
	right: 0;
}*//*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 30.10.2019, 12:32:01
    Author     : hansjoerg
*/
