.f-fieldset-wrapper {
  background-color: color('brand-primary-10');
  
  & + .f-fieldset-wrapper {
    margin-top: 1rem;
  }
}

fieldset {
  border: 0 none;
  padding: 1.5rem;
}

legend {
  color: color('brand-primary');
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  padding-top: 1rem;
}

label {
  color: color('grey-900');
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: right;
}

.required::before {
  content: "* ";
  color: color('alert');
}

.f-group-wrapper {
  & + .f-group-wrapper {
    margin-top: 1rem;
  }
}

.f-group {
  //clear: both;
  display: flex;
  gap: 1rem;
  
  & + .f-group {
    margin-top: 1rem;
  }
}

.f-label {
  flex: 0 1 30%;
}

.f-field-wrapper {
  flex: 0 1 70%;
}

.f-field-wrapper--recall,
.f-field-wrapper--salutation {
  display: flex;
  gap: 1rem;
  
  & > div {
	flex: 1;
  }
}

input:not([type="submit"]), 
textarea, 
select {
  @include ff('primary');
  background-color: color('white');
  border: 0 none;
  font-size: 1.3rem;
  padding: .25rem;
  width: 100%;
}

.button.button--submit {
    &[disabled="disabled"],
    &.formNotValid {
        cursor: not-allowed;
        //cursor: default;
        background-color: #cccccc;
    }
}

.f-field-wrapper--cc {
  //display: flex;
  //gap: 1rem;
  
  label {
    align-items: baseline;
    display: flex;
    gap: .5rem;
    text-align: left;
  }
  
  input {
    width: auto;
  }
}
  
textarea {
  height: 25rem;
}

.radio {
  label:not(.f-label) {
    display: flex;//inline-block;
    gap: .25rem;
  }
}

.f-description-wrapper {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin-top: 1rem;

    p {
      flex: 0 1 68%;
    }
}

.f-group-wrapper {
  &.checkbox {
    margin-left: 32%;
  }  
}

.submit {
  text-align: right;
}

.js-option {
  display: none;
}

.f-ThanxContainer {
  background-color: rgba(color('success'), .1);
  border-radius: var(--step-2);  
  display: none;
  padding: 2rem;
  position: relative;
}

.f-ThanxContainer-CodeButton {
    background-color: #c00;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    height: 2.4rem;
    //line-height: 1.6;
    position: absolute;
    right: 1rem;
    text-align: center;
    top: 1rem;
    width: 2.4rem;
}

.f-ThanxContainer__headline {
  color: color('success');
  font-weight: bold;
  margin: 1.2rem 0 .6rem 0;
}

.f-ErrorWrapper {
    //clear: right;
    color: #fff;
    //left: 29%;
    margin-bottom: -1rem;
    position: relative;
    top: -1rem;
    width: 100%;
    
    .is-layout-rows & {
      left: 0;
      top: -0.75rem;
      width: 100%;
    }
    
    .radio &
    {
      margin-bottom: -1rem;
      top: -1rem;      
    }
    
    &:before {
        @include triangle(1rem, #c00, bottom);
        position: relative;
        left: 1rem;
    }
    
    .f-ErrorList {
        background-color: #c00;
        border-radius: var(--step--4);
        margin-left: 0;
        
        &.f-ErrorList-single {
            list-style: outside none none;
        }
        
        li {
          padding: .5rem 1rem;
        }
    }

  &.f-ErrorWrapper_anrede {
    left: 32%;
    width: 68%;
  }
}

//@include at-breakpoint($computer) {
//  .f-ErrorWrapper {
//    margin-left: span($f-column-label of $f-columns);
//  }
//}
