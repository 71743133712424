.c-header {
  display: flex;
  justify-content: space-between;
  position: relative;
  
  padding-left: var(--step-4);
  padding-right: var(--step-2);
  @include breakpoint($sm) {
    padding-left: 0;
    padding-right: 0;
  }

  svg {
    height: 100%;
    width: auto;
  }
  
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(var(--step-8) * .1);
    background-color: color('brand-primary');
    z-index: 0;
    
    @include breakpoint($md_s) {
      height: calc(var(--step-7) * .1);
    }
  }
  
  .c-header__logo {
    position: relative;
    display: block;
    padding-top: var(--step-4);
    height: var(--step-8);
    width: auto;
    
    @include breakpoint($md_s) {
      height: var(--step-7);
    }
  }
  
  .c-header__uz-wrapper {
    background-color: color('brand-primary');
    
    .c-header__uz {
      width: calc(var(--step-8) * 2);
    }
  }

  .c-header__sponsoren {
    padding-top: var(--step-1);
    
    display: flex;
    justify-content: flex-end;
    gap: var(--step-1);

    p {
      color: color('grey-700');
      font-size: var(--step--2);
      line-height: 1.3;
      text-align: right;
    }
    
    img {
      height: var(--step-4);
      width: auto;
    }
  }
}
